<template>
  <CFooter>
    <div class="mr-auto">
      <span>© {{new Date() | moment("YYYY")}} - <a :href="`https://${environmentHelpers.environment_website}`" target="_blank">{{environmentHelpers.environment_name}}</a> - {{$t('footer.All_rights_reserved')}}.</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {
      environmentTag: null,
      environmentHelpers: {
        environment_name: null,
        environment_website: null
      }            
    }
  },
  mounted: function() {
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    if(localStorage.getItem('environmentHelpers') !== null) this.environmentHelpers = JSON.parse(localStorage.getItem('environmentHelpers'));
  }
}
</script>
