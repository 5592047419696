<template>
  <CCard class="mb-0 loyalty_store_order_card">
    <CCardBody class="p-0">
      <div class="d-flex align-items-center">
        <div class="order_product_image">
          <div v-if="order.product_image_id && order.from_marketplace === 'N'" 
               class="h-100 w-100 product_image"
               v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product/' + order.loyalty_store_product_id_external + '/' + order.product_image_id + '.jpg' + '/' + clientToken + ')' }">
          </div>
          <div v-else-if="order.product_image_id && order.from_marketplace === 'Y'" 
               class="h-100 w-100 product_image"
               v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product-mp/' + order.loyalty_store_product_id_external + '/' + order.product_image_id + '.jpg' + '/' + clientToken + ')' }">
          </div>          
          <div v-else-if="order.loyalty_store_partner_product_external_id"             
              v-bind:style="{ backgroundImage: 'url(' + order.loyalty_store_partner_product_image_url + ')' }"
              class="h-100 w-100 product_image">
          </div>
          <div v-else class="h-100 w-100 d-flex align-items-center justify-content-center product_icon">          
            <i class="fa-solid fa-gift"></i>
          </div>
        </div>
        <div class="flex-grow-1 p-2_5 pr-0 order_details">
          <div>
            <p v-line-clamp="1" class="title m-0">
              <span class="mr-1">{{order.product_name}}</span>
              <span class="d-block mt-1 meta" v-html="order.variation_name"></span>
            </p>
          </div>
          <div class="d-flex align-items-center meta mt-1">
            <span class="mr-2">
              <i class="fas fa-receipt mr-1"/>{{order.order_number}}
            </span>
            <span class="mr-2">
              <i class="fas fa-clock mr-1"/>{{order.ordered | moment("DD-MM-YYYY HH:mm")}}
            </span>            
            <span v-if="order.order_delivered === 'Y'" class="delivered">
              {{$t('store.Delivered')}}
            </span>                
          </div>                
        </div>
      </div>      
    </CCardBody>
  </CCard>
</template>

<script>

export default {
  name: 'loyaltyStoreOrder',
  props: ['order'],
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }
}
</script>